import React from 'react';
import styles from './styles.module.scss';

const ProgressBar = ({ width, percent }) => {
  let progress = percent * width;
  return (
    <div className={styles.progressContainer} style={{ width: width }}>
      <div style={{ width: `${progress}px` }} className={styles.progress} />
    </div>
  );
};

export default ProgressBar;
