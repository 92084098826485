const initialState = { animations: true, volume: 100 };

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_ANIMATION': {
      return { ...state, animations: !state.animations };
    }
    case 'SET_VOLUME': {
      return { ...state, volume: action.value };
    }
    default:
      return state;
  }
};

export const toggleAnimation = () => {
  return { type: 'TOGGLE_ANIMATION' };
};

export const setVolume = (value) => {
  return { type: 'SET_VOLUME', value };
};

export default settingsReducer;
