const initialState = {
  game: '',
  mode: 0, //mode - 0 manual, mode - 1 auto
  autoBetRunning: true,
  autoBetInitial: 0,
  moneyMachineMultiplier: 2,
  betState: false,
  manual: {
    amount: 0,
  },
  auto: [
    { amount: 0, name: 'amount', label: 'bet_amount', disabled: false },
    { betsNumber: 0, name: 'betsNumber', label: 'number_of_bets', disabled: false },
    { onWin: 0, name: 'onWin', label: 'on_win', disabled: true },
    { onLoss: 0, name: 'onLoss', label: 'on_loss', disabled: true },
    { profitStop: 0, name: 'profitStop', label: 'stop_on_profit', disabled: false },
    { lossStop: 0, name: 'lossStop', label: 'stop_on_loss', disabled: false },
  ],
};

const betReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CHANGE_MODE': {
      return { ...state, mode: action.data.mode, game: action.data.name };
    }
    case 'SET_AMOUNT': {
      return {
        ...state,
        manual: { ...state.manual, amount: action.value },
        auto: state.auto.map((obj) => {
          return Object.keys(obj).includes('amount') ? { ...obj, amount: action.value } : obj;
        }),
        autoBetInitial: action.value,
      };
    }

    case 'SET_INPUT_AUTO': {
      const { name, value } = action.data;
      return {
        ...state,
        autoBetInitial: name === 'amount' ? value : state.autoBetInitial,
        auto: state.auto.map((obj) => {
          return Object.keys(obj).includes(name) ? { ...obj, [name]: value } : obj;
        }),
      };
    }
    case 'SET_BETS_NUMBER': {
      return {
        ...state,
        auto: state.auto.map((obj) => {
          return Object.keys(obj).includes('betsNumber') ? { ...obj, betsNumber: action.value } : obj;
        }),
      };
    }

    case 'TOGGLE_AUTO_BET': {
      return {
        ...state,
        autoBetRunning: !state.autoBetRunning,
        auto: state.auto.map((value) => {
          if ((value.name === 'onWin' && value.onWin === 0) || (value.name === 'onLoss' && value.onLoss === 0)) {
            return { ...value, disabled: true };
          }
          return { ...value, disabled: !value.disabled };
        }),
      };
    }
    case 'DIVIDE_OR_MULTIPLY': {
      console.log(action.data);
      const { operator, max, min } = action.data;
      return {
        ...state,
        manual: {
          ...state.manual,
          amount:
            operator === '/'
              ? state.manual.amount / 2 > min
                ? state.manual.amount / 2
                : min
              : state.manual.amount * 2 < max
              ? state.manual.amount * 2
              : max,
        },
        auto: state.auto.map((input) => {
          return Object.keys(input).includes('amount')
            ? {
                ...input,
                amount:
                  operator === '/'
                    ? state.manual.amount / 2 > min
                      ? state.manual.amount / 2
                      : min
                    : state.manual.amount * 2 < max
                    ? state.manual.amount * 2
                    : max,
              }
            : { ...input };
        }),
      };
    }
    case 'CHANGE_ON_WIN_OR_LOSE': {
      return {
        ...state,
        auto: state.auto.map((input) => {
          return Object.keys(input).includes('amount') ? { ...input, amount: action.value } : input;
        }),
      };
    }
    case 'ENABLE_INPUT': {
      return {
        ...state,
        auto: state.auto.map((input) => {
          return input.name === action.name ? { ...input, disabled: false } : input;
        }),
      };
    }
    case 'RESET_INPUT_PERCENT': {
      return {
        ...state,
        auto: state.auto.map((input) => {
          return input.name === action.name ? { ...input, [action.name]: 0, disabled: true } : input;
        }),
      };
    }
    case 'RESET_BETS_NUMBER': {
      return {
        ...state,
        auto: state.auto.map((input) => {
          return input.name === 'betsNumber' ? { ...input, betsNumber: 0 } : input;
        }),
      };
    }
    case 'CHANGE_MONEY_MACHINE_MULTIPLIER': {
      return { ...state, moneyMachineMultiplier: action.value };
    }
    case 'SET_BET_STATE': {
      return { ...state, betState: action.value };
    }
    case 'RESTORE_BETS_DEFAULT': {
      return initialState;
    }

    default:
      return state;
  }
};

export const changeBetAmount = (value) => {
  return { type: 'SET_AMOUNT', value };
};

export const changeAutoInput = (data) => {
  // data must have name and value
  return { type: 'SET_INPUT_AUTO', data };
};

export const handleAmountChange = (data) => {
  return { type: 'DIVIDE_OR_MULTIPLY', data };
};

export const changeOnWinLoss = (value) => {
  return { type: 'CHANGE_ON_WIN_OR_LOSE', value };
};

export const changeBetMode = (data) => {
  return { type: 'CHANGE_MODE', data };
};

export const toggleAutoBet = () => {
  return { type: 'TOGGLE_AUTO_BET' };
};

export const setInputEnabled = (name) => {
  return { type: 'ENABLE_INPUT', name };
};

export const resetInputPercent = (name) => {
  return { type: 'RESET_INPUT_PERCENT', name };
};

export const decreaseBetsNumber = (value) => {
  return { type: 'SET_BETS_NUMBER', value };
};

// moneyMachine

export const changeMoneyMachineMultiplier = (value) => {
  return { type: 'CHANGE_MONEY_MACHINE_MULTIPLIER', value };
};

//else

export const restoreBetsDefault = () => {
  return { type: 'RESTORE_BETS_DEFAULT' };
};

export const setBetState = (value) => {
  return { type: 'SET_BET_STATE', value };
};

export const resetBetsNumber = () => {
  return { type: 'RESET_BETS_NUMBER' };
};

export default betReducer;
