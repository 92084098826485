import { combineReducers, compose, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import animationReducer from './reducers/animationReducer';
import betReducer from './reducers/betReducer';
import gemsReducer from './reducers/gemsReducer';
import generalReducer from './reducers/generalReducer';
import infoReducer from './reducers/infoReducer';
import moneyMachineReducer from './reducers/moneyMachineReducer';
import modalsReducer from './reducers/modalsReducer';
import settingsReducer from './reducers/settingsReducer';
import userReducer from './reducers/userReducer';
import verifyReducer from './reducers/verifyReducer';
import freeSpinReducer from './reducers/freeSpinReducer';

const reducers = combineReducers({
  general: generalReducer,
  bet: betReducer,
  gems: gemsReducer,
  moneyMachine: moneyMachineReducer,
  settings: settingsReducer,
  animation: animationReducer,
  info: infoReducer,
  modals: modalsReducer,
  user: userReducer,
  verify: verifyReducer,
  spins: freeSpinReducer,
});

const loadState = () => {
  try {
    const localState = sessionStorage.getItem('state');
    if (!localState) {
      return undefined;
    } else {
      return JSON.parse(localState);
    }
  } catch (err) {
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const localState = JSON.stringify(state);
    sessionStorage.setItem('state', localState);
  } catch (err) {
    ////console.log(err);
  }
};
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducers, loadState(), composeEnhancers(applyMiddleware(thunk)));

store.subscribe(() => {
  let state = store.getState();
  let stateToSave = { settings: state.settings };
  saveState(stateToSave);
});

export default store;
