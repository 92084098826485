const initialState = {
  freeSpinList: [],
  activeFreeSpin: {
    game_id: 1,
    ticket_id: '',
    bet: 20,
    spin_count: 5,
    win_total: 0,
    min_multiplier: 0,
    max_multiplier: -1,
    currency: 'USD',
    left_spins: 0,
    expire_on: null,
    status: null,
  },
  freeSpinStatus: false,
};

const freeSpinReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_FREE_SPINS': {
      return { ...state, freeSpinList: [...action.data] };
    }
    case 'SET_ACTIVE_FREE_SPIN': {
      return {
        ...state,
        activeFreeSpin: { ...action.freeSpinData },
        freeSpinStatus: true,
      };
    }
    case 'UPDATE_FREE_SPINS': {
      console.log("========================")
      const freeSpins = [...state.freeSpinList];
      if (freeSpins.length) {
        const foundFreeSpin = freeSpins.findIndex(
          (element) => state.activeFreeSpin.ticket_id === element.ticket_id,
        );
        if (foundFreeSpin > -1) {
          freeSpins[foundFreeSpin] = action.updatedFreeSpinData;
        }
      }
      return {
        ...state,
        activeFreeSpin: { ...action.updatedFreeSpinData },
        freeSpinList: [...freeSpins]
      };
    }
    case 'TOGGLE_FREE_SPIN_STATUS': {
      return {
        ...state,
        activeFreeSpin: {
          ...state.activeFreeSpin,
        },
        freeSpinStatus: !state.freeSpinStatus,
      };
    }
    case 'DISABLE_FREE_SPIN': {
      const freeSpins = [...state.freeSpinList];
      if (freeSpins.length) {
        const foundFreeSpin = freeSpins.findIndex(
          (element) => state.activeFreeSpin.ticket_id === element.ticket_id,
        );
        if (foundFreeSpin > -1) {
          freeSpins.splice(foundFreeSpin, 1);
        }
      }
      const activeFreeSpin = (freeSpins.length) ? freeSpins[0] : {
        game_id: 1,
        ticket_id: '',
        bet: 20,
        spin_count: 5,
        win_total: 0,
        min_multiplier: 0,
        max_multiplier: -1,
        currency: 'USD',
        left_spins: 0,
        expire_on: null,
        status: null,
      };
      return {
        ...state,
        activeFreeSpin: {
          ...activeFreeSpin,
        },
        freeSpinStatus: false,
        freeSpinList: [...freeSpins]
      };
    }
    case 'RESTORE_DEFAULTS': {
      return { ...initialState, freeSpinList: [...state.freeSpinList] };
    }
    default:
      return state;
  }
};

export const setFreeSpins = (data) => {
  return { type: 'SET_FREE_SPINS', data };
};

export const setActiveFreeSpin = (freeSpinData) => {
  return { type: 'SET_ACTIVE_FREE_SPIN', freeSpinData };
};

export const toggleFreeSpinStatus = () => {
  return { type: 'TOGGLE_FREE_SPIN_STATUS' };
};
export const disableFreeSPin = () => {
  return { type: 'DISABLE_FREE_SPIN' };
};

export const updateFreeSpin = (updatedFreeSpinData) => {
  return { type: 'UPDATE_FREE_SPINS', updatedFreeSpinData };
};

export const restoreFreeSpinDefaults = () => {
  return { type: 'RESTORE_DEFAULTS' };
};

export default freeSpinReducer;
