import React, { useRef } from 'react';
import { createContext } from 'react';

export const AppContext = createContext(null);

const RefProvider = ({ children }) => {
  const appRef = useRef(null);

  return <AppContext.Provider value={appRef}>{children}</AppContext.Provider>;
};

export default RefProvider;
