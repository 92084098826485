import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import AmunGaming from '../../assets/amungaming.png';
import ProgressBar from './progressBar/ProgressBar';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const PreLoader = () => {
  const { width } = useWindowDimensions();
  const [percent, setPercent] = useState(0);
  useEffect(() => {
    setPercent(1);
  }, []);

  return (
    <div className={styles.preloaderContainer}>
      <div className={styles.innerContainer}>
        <ProgressBar width={width > 425 ? 400 : width - 10} percent={percent} />
        <div className={styles.transitionDiv}>
          <p>Powered by</p>
          <img src={AmunGaming} alt='UXGames' />
        </div>
      </div>
    </div>
  );
};

export default PreLoader;
