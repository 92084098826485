const initialState = {
  fairnessOpen: false,
  gameInfoOpen: false,
  usernameRequired: false,
  infoOpen: false,
  smallModalOpen: false,
  gameInfo: {},
  smallModalInfo: {},
  seedLatest: {},
};

const modalsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'OPEN_MODAL': {
      return { ...state, [action.name]: true, smallModalInfo: action.data ? { ...action.data } : {} };
    }

    case 'CLOSE_MODAL': {
      return { ...state, [action.name]: false };
    }

    case 'SET_GAME_INFO': {
      return { ...state, gameInfo: action.data };
    }

    case 'SET_SEED': {
      return { ...state, seedLatest: action.data };
    }

    default:
      return state;
  }
};

export const openModal = (name, data) => {
  return { type: 'OPEN_MODAL', name, data };
};

export const closeModal = (name) => {
  return { type: 'CLOSE_MODAL', name };
};

export const setGameInfoProps = (data) => {
  return { type: 'SET_GAME_INFO', data };
};

export const setSeed = (data) => {
  return { type: 'SET_SEED', data };
};

export default modalsReducer;
