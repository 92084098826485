import './wdyr';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import 'antd/dist/antd.css';
import WebSocketProvider from './context/WebSocketProvider';
import store from './redux/store';
import './i18n';
import { Provider } from 'react-redux';
import PreLoader from './components/preloader/PreLoader';
import RefProvider from './context/RefProvider';

const App = React.lazy(() => {
  return Promise.all([import('./App'), new Promise((resolve) => setTimeout(resolve, 1500))]).then(
    ([moduleExports]) => moduleExports
  );
});

// const App = React.lazy(() => import('./App'));

ReactDOM.render(
  <Provider store={store}>
    <WebSocketProvider>
      <Suspense fallback={<PreLoader />}>
        <RefProvider>
          <App />
        </RefProvider>
      </Suspense>
    </WebSocketProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log());
