const initialState = {
  serverOut: false,
  tokenExpired: false,
  gameDisabled: false,
  connectingToServer:false,
  limits: {},
  games: null,
  language: 'en-US',
  url: null,
  deposit: null,
  logo: null,
};

const generalReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CONNECTING_TO_SERVER': {
      return {...state, connectingToServer: action.value};
    }
    case 'SET_SERVER_OUT': {
      return {...state, serverOut: action.value};
    }
    case 'SET_TOKEN_EXPIRED': {
      return {...state, tokenExpired: action.value};
    }
    case 'SET_GAME_DISABLED': {
      return {...state, gameDisabled: action.value};
    }
    case 'SET_GENERAL_SETTINGS': {
      return {...state, ...action.data};
    }
    default:
      return state;
  }
};

export const setConnectingToServer = (value) => {
  return {type: 'SET_CONNECTING_TO_SERVER', value};
};

export const setServerOut = (value) => {
  return {type: 'SET_SERVER_OUT', value};
};

export const setTokenExpired = (value) => {
  return {type: 'SET_TOKEN_EXPIRED', value};
};

export const setGameDisabled = (value) => {
  return {type: 'SET_GAME_DISABLED', value};
};

export const setGeneralSettings = (data) => {
  return {type: 'SET_GENERAL_SETTINGS', data};
};

export default generalReducer;
