import React from 'react';
import {createContext} from 'react';
import io from 'socket.io-client';
import {useDispatch} from 'react-redux';
import {showResults, zeroGems} from '../redux/reducers/gemsReducer';
import {
  changeOnWinLoss,
  decreaseBetsNumber,
  setBetState,
  toggleAutoBet,
} from '../redux/reducers/betReducer';
import {
  restoreDefaultAnimationState,
  startExitAnimation,
} from '../redux/reducers/animationReducer';
import {setInitialInfo, updateInfo} from '../redux/reducers/infoReducer';
import {
  closeModal,
  setGameInfoProps,
  openModal,
  setSeed,
} from '../redux/reducers/modalsReducer';
import {setUserInfo, userUpdate} from '../redux/reducers/userReducer';
import {showMoneyMachineResults} from '../redux/reducers/moneyMachineReducer';
import {
  setConnectingToServer,
  setGameDisabled,
  setGeneralSettings, setServerOut,
  setTokenExpired,
} from '../redux/reducers/generalReducer';
import i18next from 'i18next';
import {
  setFreeSpins,
  updateFreeSpin,
} from '../redux/reducers/freeSpinReducer';

export const WebSocketContext = createContext(null);
let animationDelay = 250;
const url = new URL(window.location);
// console.log(url);
const token = url.searchParams.get('token');
if (token) {
  localStorage.setItem('token', token);
}
// console.log('token', token);

const WebSocketProvider = ({children}) => {
  const localToken = localStorage.getItem('token');
  // console.log(localToken);
  const dispatch = useDispatch();
  dispatch(setConnectingToServer(true));
  const socket = io(process.env.REACT_APP_PROD_URL || 'ws://127.0.0.1:4000', {
    transports: ['websocket'],
    query: {
      token: localToken || 'BnbXoCKXcvs7KatQUKGVxjVQxcBYnqhA6CdgrsI3FFV',
    },
  });
  // console.log('SOCKET', socket);
  socket.on('connect', () => {
    /// TODO: debug
  });

  socket.on('reconnect', () => {
    dispatch(setConnectingToServer(true));
  });

  socket.on('connect_error', (error) => {
    dispatch(setConnectingToServer(false));
    if (error.message === 'websocket error') {
      return dispatch(setServerOut(true));
    }
    if (error.message === 'games_disabled') {
      return dispatch(setGameDisabled(true));
    }
    dispatch(setTokenExpired(true));
  });
  socket.on('error:connect', () => {
    // console.log('EXPIRED TOKEN');
    dispatch(setTokenExpired(true));
  });
  socket.on('config', (res) => {
    // console.log('configs', res);
    const {language} = res;
    // console.log(language);
    dispatch(setGeneralSettings(res));
    dispatch(setConnectingToServer(false));
    language && i18next.changeLanguage(language);
  });
  //initial 10 results for info
  socket.on('info:bets', (res) => {
    dispatch(setInitialInfo(res));
  });
  //info user
  socket.on('info:user', (res) => {
    // console.log('USER INFO', { ...res, balance: parseFloat(res.balance) });
    dispatch(setUserInfo(res));
  });
  //if no username
  socket.on('username:required', () => {
    dispatch(openModal('usernameRequired'));
  });
  //listen for new bet and update info table
  socket.on('new:bet', (res) => {
    // console.log('NEW BET RESULT', res);
    if (res && res.game && res.game.gin && res.game.gin === 'gem_rush') {
      animationDelay = 1500;
    } else {
      animationDelay = 250;
    }
    setTimeout(() => {
      dispatch(updateInfo(res));
    }, animationDelay);
  });

  //set free spins
  socket.on('free:spins', (res) => {
    // console.log('FREE SPINS', res);
    dispatch(setFreeSpins(res));
  });
  socket.on('update:free:spin', (res) => {
    // console.log('UFS',res)
    setTimeout(() => {
      dispatch(updateFreeSpin(res));
    }, animationDelay);
  });

  //set username
  const handleUsernameSubmit = (username) => {
    socket.emit('username:set', {username}, (res) => {
      res.success && dispatch(closeModal('usernameRequired'));
    });
  };

  const getSeed = () => {
    socket.emit('get:seed', (res) => {
      const {success, ...data} = res;
      success && dispatch(setSeed(data));
    });
  };

  const rotateSeed = (seed) => {
    socket.emit('update:seed', {seed}, (res) => {
      const {success, ...data} = res;
      success && dispatch(setSeed(data));
    });
  };

  const getGameInfo = (betID) => {
    socket.emit(
      'get:bet',
      {
        betID: betID,
      },
      (res) => {
        const {result} = res;
        // console.log(res);
        dispatch(setGameInfoProps(result));
      },
    );
  };

  const handleManualBet = (data) => {
    // console.log('MANUAL BET', data);
    socket.emit('bet', data, (res) => {
      const {result, user, error} = res;
      if (error) {
        dispatch(setBetState(false));
        dispatch(openModal('smallModalOpen', error));
      }
      // const test = { ...result, multiplier: 5, gems: ['red', 'red', 'red', 'red', 'blue'] }; //to test big win
      if (data.gameID === 1) {
        animationDelay = 1500;
        result && dispatch(showResults(result));
      }
      if (data.gameID === 2) {
        animationDelay = 250;
        result && dispatch(showMoneyMachineResults(result));
      }
      setTimeout(() => {
        user &&
        dispatch(userUpdate({...user, balance: user.balance.toFixed(0)}));
        dispatch(setBetState(false));
      }, animationDelay);
    });
  };

  let timer;
  let betNum;
  let onWin;
  let onLoss;
  let totalProfit = 0;
  let totalLoss = 0;
  let newData = {};
  let initialBet;
  let totalBet = 0;
  const handleAutoBet = (data, delay) => {
    // console.log(data, delay);
    newData = {...data};
    betNum = parseInt(data.betsNumber);
    onWin = parseInt(data.onWin);
    onLoss = parseInt(data.onLoss);
    initialBet = parseFloat(data.initialBet);
    // console.log('initial bet', initialBet);
    totalBet += parseFloat(data.amount);
    console.log('TOTAL BET', totalBet);
    //empty gems before new ones
    if (data.running) {
      socket.emit('bet', data, (res) => {
        const {result, user, error} = res;
        if (error) {
          // dispatch(setBetState(false));
          dispatch(toggleAutoBet());
          dispatch(openModal('smallModalOpen', error));
          totalProfit = 0;
          totalLoss = 0;
          clearTimeout(timer);
          setTimeout(() => {
            dispatch(setBetState(false));
          }, animationDelay);
          return;
        }
        // console.log('RESULT', result, 'ERROR', error);
        let win = data.amount * result.multiplier >= data.amount;
        let lose = data.amount * result.multiplier < data.amount;
        // console.log('win', win, 'lose', lose);
        // console.log(
        //   'amount lost :',
        //   data.amount - data.amount * result.multiplier,
        // );

        totalLoss = lose
          ? totalLoss + (data.amount - data.amount * result.multiplier)
          : totalLoss;
        totalProfit = win
          ? totalProfit + (data.amount * result.multiplier - data.amount)
          : totalProfit;

        console.log('total loss:', totalLoss, 'total profit:', totalProfit);

        // const test = { ...result, multiplier: 50 }; // to test big win
        if (data.gameID === 1) {
          animationDelay = 1500;
          result && dispatch(showResults(result));
        }
        if (data.gameID === 2) {
          animationDelay = 250;
          result && dispatch(showMoneyMachineResults(result));
        }
        if (data.profitStop > 0 && totalProfit >= data.profitStop) {
          console.log(data.profitStop, totalProfit);
          totalProfit = 0;
          totalLoss = 0;
          dispatch(toggleAutoBet());
          clearTimeout(timer);
          setTimeout(() => {
            dispatch(setBetState(false));
          }, animationDelay);
          return;
        }
        if (data.lossStop > 0 && totalLoss >= data.lossStop) {
          console.log(data.lossStop, totalLoss);
          dispatch(toggleAutoBet());
          totalProfit = 0;
          totalLoss = 0;
          clearTimeout(timer);
          setTimeout(() => {
            dispatch(setBetState(false));
          }, animationDelay);
          return;
        }

        //if number of bets is mentioned
        if (betNum && betNum > 0) {
          let newBetNum = betNum - 1;
          newData = {...data, betsNumber: newBetNum};
          dispatch(decreaseBetsNumber(newBetNum));
          if (betNum === 1) {
            dispatch(toggleAutoBet());
            console.log(data.betsNumber, betNum);
            newData = {...data, running: false};
          }
        }
        //check if user input onWin exists
        setTimeout(() => {
          if (onWin > 0 && win) {
            //increase bet amount by onWin percentage
            newData = {
              ...newData,
              amount:
                parseFloat(data.amount) * (onWin / 100) +
                parseFloat(data.amount),
            };
            console.log(newData);
            dispatch(changeOnWinLoss(newData.amount));
          }

          //reset if onWin chosen but game lost
          if (onWin > 0 && onLoss === 0 && lose) {
            //increase bet amount by onWin percentage
            newData = {...newData, amount: parseFloat(initialBet)};
            dispatch(changeOnWinLoss(newData.amount));
          }

          //check if user input onLoss exists
          if (onLoss > 0 && lose) {
            //increase bet amount by onLose percentage
            newData = {
              ...newData,
              amount:
                parseFloat(data.amount) * (onLoss / 100) +
                parseFloat(data.amount),
            };
            dispatch(changeOnWinLoss(newData.amount));
          }
          //reset if onLoss chosen but game won
          if (onLoss > 0 && onWin === 0 && win) {
            newData = {...newData, amount: parseFloat(initialBet)};
            dispatch(changeOnWinLoss(newData.amount));
          }

          dispatch(setBetState(false));
        }, animationDelay);

        // console.log(newData);
        timer = setTimeout(() => {
          setTimeout(() => {
            if (newData.gameID === 1) {
              dispatch(zeroGems());
              dispatch(restoreDefaultAnimationState());
            }
            handleAutoBet(newData, delay);
          }, delay);
          newData.gameID === 1 && dispatch(startExitAnimation());
          user &&
          dispatch(userUpdate({...user, balance: user.balance.toFixed(0)}));
        }, animationDelay);
      });
    } else {
      clearTimeout(timer);
      totalProfit = 0;
      totalLoss = 0;
      totalBet = 0;
      return;
    }
  };

  const ws = {
    socket: socket,
    handleManualBet,
    handleAutoBet,
    handleUsernameSubmit,
    getGameInfo,
    getSeed,
    rotateSeed,
  };
  return (
    <WebSocketContext.Provider value={ws}>{children}</WebSocketContext.Provider>
  );
};

export default WebSocketProvider;
