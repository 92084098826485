const initialState = { result: {}, formData: { clientSeed: '', serverSeed: '', nonce: '' }, gameName: 'gemRush' };

const verifyReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_VERIFY_RESULT': {
      return { ...state, result: { ...action.result } };
    }

    case 'CHANGE_VERIFY_DATA': {
      return { ...state, formData: { ...state.formData, [action.data.name]: action.data.value } };
    }
    case 'SET_VERIFY_GAME_NAME': {
      return { ...state, gameName: action.value };
    }
    case 'RESTORE_VERIFY_DEFAULT':
      return initialState;

    default:
      return state;
  }
};

export const setVerifyResult = (result) => {
  return { type: 'SET_VERIFY_RESULT', result };
};

export const changeVerifyDataValue = (data) => {
  return { type: 'CHANGE_VERIFY_DATA', data };
};
export const restoreVerifyDefault = () => {
  return { type: 'RESTORE_VERIFY_DEFAULT' };
};

export const setVerifyGameName = (value) => {
  return { type: 'SET_VERIFY_GAME_NAME', value };
};

export default verifyReducer;
