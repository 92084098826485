const initialState = { rolled: null, multipliers: [], newRound: false, className: '' };

const moneyMachineReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_MONEY_MACHINE_RESULT': {
      return {
        ...state,
        ...action.result,
        newRound: true,
      };
    }
    case 'CHANGE_MONEY_MACHINE_MULTIPLIERS': {
      return {
        ...state,
        multipliers:
          state.multipliers.length < 6
            ? [{ target: action.result, win: state.win, key: state.betId }, ...state.multipliers]
            : [{ target: action.result, win: state.win, key: state.betId }].concat(state.multipliers.slice(0, -1)),
        newRound: false,
        className: state.win > 0 ? 'win' : 'lose',
      };
    }

    // case 'NEW_ROUND_TRUE': {
    //   return { ...state, newRound: true, className: '' };
    // }

    default:
      return state;
  }
};

export const showMoneyMachineResults = (result) => {
  return (dispatch) => {
    dispatch({ type: 'SET_MONEY_MACHINE_RESULT', result });
  };
};

export const changeMoneyMachineMultipliers = (result) => {
  return { type: 'CHANGE_MONEY_MACHINE_MULTIPLIERS', result };
};

// export const setNewRoundTrue = () => {
//   return { type: 'NEW_ROUND_TRUE' };
// };

export default moneyMachineReducer;
