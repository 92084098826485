const initialState = { last10: [], top10: [], userLast10: [], activeTab: 'last10', classState: false };

const infoReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_INITIAL_INFO': {
      return { ...state, ...action.data };
    }
    case 'SET_ACTIVE_TAB': {
      return { ...state, activeTab: action.name };
    }
    case 'UPDATE_INFO': {
      return {
        ...state,
        last10:
          state.last10.length < 10
            ? [action.data].concat(state.last10)
            : [action.data].concat(state.last10.slice(0, -1)),
        userLast10:
          state.userLast10.length < 10
            ? [action.data].concat(state.userLast10)
            : [action.data].concat(state.userLast10.slice(0, -1)),
        classState: !state.classState,
      };
    }
    default:
      return state;
  }
};

export const setInitialInfo = (data) => {
  return { type: 'SET_INITIAL_INFO', data };
};

export const setActiveTab = (name) => {
  return { type: 'SET_ACTIVE_TAB', name };
};

export const updateInfo = (data) => {
  return { type: 'UPDATE_INFO', data };
};

export default infoReducer;
