const initialState = { gemsColors: [], groupedGems: { gemNumbers: [], total: 0 }, startExit: false };

const animationReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_GEMS_ANIMATION': {
      return { ...state, gemsColors: [...state.gemsColors, action.color] };
    }
    case 'RESTORE_DEFAULT_ANIMATION': {
      return initialState;
    }
    case 'SET_GROUPED_GEMS': {
      return { ...state, groupedGems: { ...action.animationData } };
    }
    case 'START_EXIT': {
      return { ...state, startExit: true };
    }
    default:
      return state;
  }
};

export const setGemsAnimation = (color) => {
  return { type: 'SET_GEMS_ANIMATION', color };
};

export const restoreDefaultAnimationState = () => {
  return { type: 'RESTORE_DEFAULT_ANIMATION' };
};

export const setGroupedGems = (animationData) => {
  return { type: 'SET_GROUPED_GEMS', animationData };
};

export const startExitAnimation = () => {
  return { type: 'START_EXIT' };
};

export default animationReducer;
