const initialState = {};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_USER_INFO': {
      return { ...action.data };
    }
    case 'UPDATE_USER_INFO': {
      return { ...action.data };
    }
    default:
      return state;
  }
};

export const setUserInfo = (data) => {
  return { type: 'SET_USER_INFO', data };
};

export const userUpdate = (data) => {
  return { type: 'UPDATE_USER_INFO', data };
};

export default userReducer;
