const initialState = { gems: [], multiplier: null, result: [], win: 0 };

const gemsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_RESULT': {
      return {
        ...state,
        gems: action.result.gems,
        multiplier: action.result.multiplier,
        result: action.result.result,
        win: action.result.win,
      };
    }
    case 'ZERO_GEMS': {
      return { ...state, gems: [] };
    }
    case 'RESET_MULTIPLIER': {
      return { ...state, multiplier: null };
    }
    default:
      return state;
  }
};

export const showResults = (result) => {
  return (dispatch) => {
    dispatch({ type: 'SET_RESULT', result });
  };
};

export const zeroGems = () => {
  return { type: 'ZERO_GEMS' };
};

export const resetMultiplier = () => {
  return { type: 'RESET_MULTIPLIER' };
};

export default gemsReducer;
